.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0, 0, 0, 0.5);*/
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: blue;
    font-size: 2rem;
    font-weight: bold;
}

.blur-content {
    filter: blur(5px);
}

.pinned-row {
    background-color: #f8f9fa; /* or any other color you prefer */
}

.modal-open {
    padding-right: 15px;
}
